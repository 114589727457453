class InsuranceTable {
    constructor() {
        this.insuranceTableItemsContainer = document.querySelectorAll('[data-expandable-item]');
    }

    init() {
        if (!this.insuranceTableItemsContainer) {
          return;
        }


        if (this.insuranceTableItemsContainer) {
            this.insuranceTableItemsContainer.forEach(function (container) {
              container.addEventListener('click', function (event) {
                  const itemId = container.dataset.expandableItem;
                  const descriptionItem = document.querySelector('.description-row.item-' + itemId);
                  const fasIconItem = event.target.querySelector('.fas');

                  if (container.classList.contains('is-open')) {
                      container.classList.remove('is-open');
                      InsuranceTable.iconVisibilityChanger(fasIconItem, true);
                  } else {
                      container.classList.add('is-open');
                      InsuranceTable.iconVisibilityChanger(fasIconItem, false);
                  }

                  InsuranceTable.descriptionVisibilityChanger(descriptionItem);
              });
          });
        }
    }

    static iconVisibilityChanger(icon, open) {
        if (icon === null) {
            return;
        }

        if (open) {
            icon.classList.remove('fa-caret-up');
            icon.classList.add('fa-caret-down');
        } else {
            icon.classList.remove('fa-caret-down');
            icon.classList.add('fa-caret-up');
        }
    }

    static descriptionVisibilityChanger(description) {
        if (description === null) {
            return;
        }

        if (description.classList.contains('is-hidden')) {
            description.classList.remove('is-hidden');
        } else {
            description.classList.add('is-hidden');
        }
    }
}

export default new InsuranceTable();